export default [{
    _name: 'CSidebarNav',
    _children: [{
            _name: 'CSidebarNavItem',
            name: 'Dashboard',
            to: '/dashboard',
            icon: 'cil-speedometer',
            badge: {
                color: 'primary',
                text: 'NEW'
            }
        },
        {
            _name: 'CSidebarNavTitle',
            _children: ['1. เมนูครู']
        },
        {
            _name: 'CSidebarNavItem',
            name: '1.1 แผงจัดการ',
            to: '/dashboardTeacher',
            icon: 'cil-chart-pie'
        },
        {
            _name: 'CSidebarNavItem',
            name: '1.2 จัดการคะแนนรายวิชา',
            to: '/dashboardUploadScore',
            icon: 'cil-chart-pie'
        },
        {
            _name: 'CSidebarNavItem',
            name: '1.3 สรุปความถี่พฤติกรรม',
            to: '/dashboardFrequency',
            icon: 'cil-chart-pie'
        },
        {
            _name: 'CSidebarNavItem',
            name: '1.4 ประวัติการทำรายการ',
            to: '/dashboardMyHistory',
            icon: 'cil-chart-pie'
        },
        {
            _name: 'CSidebarNavItem',
            name: '1.5 เพิ่มคะแนนแบบด่วน',
            to: '/dashboardQuicklyReset',
            icon: 'cil-chart-pie'
        },
        {
            _name: 'CSidebarNavTitle',
            _children: ['2. รายงาน']
        },
        {
            _name: 'CSidebarNavItem',
            name: '2.1 คะแนนพฤติกรรมรายชั้น',
            to: '/reports/mainReport',
            icon: 'cil-chart-pie'
        },
        {
            _name: 'CSidebarNavItem',
            name: '2.2 สรุปนักเรียนขาดเรียน',
            to: '/reports/classMissing',
            icon: 'cil-chart-pie'
        },
        {
            _name: 'CSidebarNavTitle',
            _children: ['3. ระเบียนประวัติ']
        },
        {
            _name: 'CSidebarNavItem',
            name: '3.1 แผงจัดการ',
            to: '/records/main',
            icon: 'cil-chart-pie'
        },
        {
            _name: 'CSidebarNavItem',
            name: '3.2 ตำบลที่พักอาศัยและแผนที่',
            to: '/records/subdistrict',
            icon: 'cil-chart-pie'
        },
        {
            _name: 'CSidebarNavTitle',
            _children: ['4. ค่าบำรุงการศึกษา']
        },
        {
            _name: 'CSidebarNavItem',
            name: '4.1 แผงจัดการ',
            to: '/dashboardTutionFee',
            icon: 'cil-chart-pie'
        },
        {
            _name: 'CSidebarNavItem',
            name: '4.2 Upload Transcation',
            to: '/uploadTransaction',
            icon: 'cil-chart-pie'
        },

        {
            _name: 'CSidebarNavTitle',
            _children: ['5. สารสนเทศโรงเรียน']
        },
        {
            _name: 'CSidebarNavItem',
            name: '5.1 บุคลากร',
            to: '/users',
            icon: 'cil-chart-pie'
        },
        {
            _name: 'CSidebarNavItem',
            name: '5.2 ผลงานโรงเรียน',
            to: '/rewards',
            icon: 'cil-chart-pie'
        },
        {
            _name: 'CSidebarNavItem',
            name: '5.7 ไฟล์สารสนเทศ',
            to: '/dashboardInformation',
            icon: 'cil-chart-pie'
        },

        {
            _name: 'CSidebarNavTitle',
            _children: ['6.  Little Bank']
        },
        {
            _name: 'CSidebarNavItem',
            name: '6.1 แผงจัดการ',
            to: '/dashboardLittleBank',
            icon: 'cil-chart-pie'
        },

        {
            _name: 'CSidebarNavTitle',
            _children: ['7. เมนูผู้บริหาร']
        },
        {
            _name: 'CSidebarNavItem',
            name: '7.1 แผงจัดการ',
            to: '/dashboardAdmin',
            icon: 'cil-chart-pie'
        },





    ]
}]