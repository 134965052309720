<template>
  <CFooter :fixed="false">
    <div>
      <span class="ml-1"
        >&copy; {{ new Date().getFullYear() }} Developed by Beaming
        Technology</span
      >
    </div>
    <div class="mfs-auto">
      <!--
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="https://coreui.io/vue">CoreUI for Vue</a>

      -->
    </div>
    <CButton @click="reloader">
      <i class="ri-refresh-line"></i>
    </CButton>
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter",
  methods:{
    reloader(){
      location.reload();
    }
  }
};
</script>
