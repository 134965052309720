<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar" v-if="userData">
          <img src="../assets/Avatar.png" class="c-avatar-img" />
          <span style="padding-left:0.1rem" class="badge badge-primary" v-if="userData.user_fname">  {{ userData.user_fname }}</span>
          <span style="padding-left:0.1rem" class="badge badge-warning" v-else-if="userData.level_id">  {{ userData.std_name }}</span>
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Settings</strong>
    </CDropdownHeader>
    <CDropdownItem @click="$router.push({path:'./userProfile'})"> <CIcon name="cil-user" /> Profile </CDropdownItem>
    <CDropdownItem> <CIcon name="cil-settings" /> Settings </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-dollar" /> Payments
      <CBadge color="secondary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-file" /> Projects
      <CBadge color="primary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownDivider />
    <CDropdownItem>
      <CIcon name="cil-shield-alt" /> Lock Account
    </CDropdownItem>
    <CDropdownItem @click="logout">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: "TheHeaderDropdownAccnt",
  data() {
    return {
      itemsCount: 42,
      userData: this.$cookies.get("user"),
    };
  },
  mounted(){
    //console.log(this.userData);
  },
  methods: {
    logout() {
      alert("กำลัง logout");
      this.$cookies.remove("user");
      //this.$router.push({ path: 'dashboard' });
      location.reload();
    },
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>