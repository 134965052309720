<template>
  <div class="c-app">
    <CWrapper>
      <div class="c-body">
        <main class="c-main bg-skw-blue">
          <div class="text-center">
            <h1>สารสนเทศนักเรียนโรงเรียนศรีสะเกษวิทยาลัย</h1>
            <h3>ประจำ {{ nDate }}</h3>
          </div>
          <carousel
            :per-page="1"
            :mouse-drag="true"
            :autoplay="true"
            :loop="true"
            :autoplayTimeout="7000"
          >
            <slide>
              <CContainer fluid>
                <CRow>
                  <CCol lg="3">
                    <div class="block-showcase">
                      <div class="text-center"><h3>มาสาย</h3></div>
                      <div>
                        <table class="table">
                          <thead>
                            <th>
                              <div class="text-center text-white">
                                <h1>{{ flagCount }}</h1>
                              </div>
                              <div class="text-right text-white">คน</div>
                            </th>
                            <th>
                              <div class="text-center text-white">
                                <h1>
                                  {{
                                    (flagCount / stdInfo.length).toLocaleString(
                                      undefined,
                                      { minimumFractionDigits: 2 }
                                    )
                                  }}
                                </h1>
                              </div>
                              <div class="text-right text-white">%</div>
                            </th>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </CCol>
                  <CCol lg="3">
                    <div class="block-showcase">
                      <div class="text-center"><h3>ขาดเรียน</h3></div>
                      <div>
                        <table class="table">
                          <thead>
                            <th>
                              <div class="text-center text-white">
                                <h1>{{ missInfo.length }}</h1>
                              </div>
                              <div class="text-right text-white">คน</div>
                            </th>
                            <th>
                              <div class="text-center text-white">
                                <h1>{{
                                    (missInfo.length / stdInfo.length).toLocaleString(
                                      undefined,
                                      { minimumFractionDigits: 2 }
                                    )
                                  }}</h1>
                              </div>
                              <div class="text-right text-white">%</div>
                            </th>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </CCol>
                  <CCol lg="3">
                    <div class="block-showcase">
                      <div class="text-center">
                        <h3>ขาดเรียนสะสมเกิน 4 ครั้ง</h3>
                      </div>
                      <div>
                        <table class="table">
                          <thead>
                            <th>
                              <div class="text-center text-white">
                                <h1>150</h1>
                              </div>
                              <div class="text-right text-white">คน</div>
                            </th>
                            <th>
                              <div class="text-center text-white">
                                <h1>27.77</h1>
                              </div>
                              <div class="text-right text-white">%</div>
                            </th>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </CCol>
                  <CCol lg="3" c>
                    <div class="block-showcase">
                      <div class="text-center"><h3>COVID + กักตัว</h3></div>
                      <div>
                        <table class="table">
                          <thead>
                            <th>
                              <div class="text-center text-white">
                                <h1>13</h1>
                              </div>
                              <div class="text-right text-white">คน</div>
                            </th>
                            <th>
                              <div class="text-center text-white">
                                <h1>0.77</h1>
                              </div>
                              <div class="text-right text-white">%</div>
                            </th>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </CCol>
                  <CCol lg="3" style="height: 300px">
                    <CCard>
                      <CCardHeader class="text-black" style="height: 75px"
                        >นักเรียนมาสายจำแนกตามระดับชั้น</CCardHeader
                      >
                      <CCardBody
                        ><CChartDoughnut
                          :datasets="chartData"
                          :labels="['ม.1', 'ม.2', 'ม.3', 'ม.4', 'ม.5', 'ม.6']"
                      /></CCardBody>
                    </CCard>
                  </CCol>
                  <CCol lg="3" style="height: 300px">
                    <CCard>
                      <CCardHeader class="text-black" style="height: 75px"
                        >นักเรียนขาดเรียนจำแนกตามระดับชั้น</CCardHeader
                      >
                      <CCardBody
                        ><CChartDoughnut
                          :datasets="chartDataMiss"
                          :labels="['ม.1', 'ม.2', 'ม.3', 'ม.4', 'ม.5', 'ม.6']"
                      /></CCardBody>
                    </CCard>
                  </CCol>
                  <CCol lg="3" style="height: 300px">
                    <CCard>
                      <CCardHeader class="text-black" style="height: 75px"
                        >นักเรียนขาดเรียนเกิน 4 ครั้ง
                        จำแนกตามระดับชั้น</CCardHeader
                      >
                      <CCardBody
                        ><CChartDoughnut
                          :datasets="chartDataMiss4"
                          :labels="['ม.1', 'ม.2', 'ม.3', 'ม.4', 'ม.5', 'ม.6']"
                      /></CCardBody>
                    </CCard>
                  </CCol>
                  <CCol lg="3" style="height: 300px">
                    <CCard>
                      <CCardHeader class="text-black" style="height: 75px"
                        >นักเรียนติด Covid/ กักตัว
                        จำแนกตามระดับชั้น</CCardHeader
                      >
                      <CCardBody
                        ><CChartDoughnut
                          :datasets="chartDataCovid"
                          :labels="['ม.1', 'ม.2', 'ม.3', 'ม.4', 'ม.5', 'ม.6']"
                      /></CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
              </CContainer>
            </slide>
            <slide>
              <CContainer fluid>
                <CRow>
                  <CCol lg="12"> </CCol>
                </CRow> </CContainer
            ></slide>
          </carousel>
        </main>
      </div>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import TheFooter from "./TheFooter";
import { CChartBar } from "@coreui/vue-chartjs";
import { CChartDoughnut } from "@coreui/vue-chartjs";
import { CChartPolarArea } from "@coreui/vue-chartjs";

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    CChartBar,
    CChartDoughnut,
    CChartPolarArea,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      nDate: null,
      chartData: [
        {
          label: "จำนวนนักเรียนที่ขาดเรียนจำแนกเป็นระดับชั้น",
          backgroundColor: this.$store.state.bgColor,
          data: [10, 10, 10, 10, 10, 10],
        },
      ],
      chartDataMiss: [
        {
          label: "จำนวนนักเรียนที่ขาดเรียนจำแนกเป็นระดับชั้น",
          backgroundColor: this.$store.state.bgColor,
          data: [50, 30, 5, 5, 5, 5],
        },
      ],
      chartDataMiss4: [
        {
          label: "จำนวนนักเรียนที่ขาดเรียนจำแนกเป็นระดับชั้น",
          backgroundColor: this.$store.state.bgColor,
          data: [10, 40, 12, 12, 13, 13],
        },
      ],
      chartDataCovid: [
        {
          label: "จำนวนนักเรียนที่ขาดเรียนจำแนกเป็นระดับชั้น",
          backgroundColor: this.$store.state.bgColor,
          data: [20, 40, 10, 10, 10, 10],
        },
      ],
      flagCount: 0,
      nowD: null,
      stdInfo: [],
      missInfo:[],
      countLate: null,
    };
  },
  mounted() {
    const date = new Date();

    this.nDate = date.toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    });

    //FETCH FRAG ROW
    let dObj = new Date();
    //console.log(this.dateConverter(dObj));
    this.nowD = this.dateConverter(dObj);

    let formFlag = new FormData();
    formFlag.append("txtAction", "getFlagPunishByDate");
    formFlag.append("dateStart", this.nowD);
    formFlag.append("schoolId", this.$route.params.schoolCode);
    formFlag.append("dateEnd", this.nowD);
    this.axios
      .post(this.$hostUrl + "php_action/attendanceAPI.php", formFlag)
      .then((response) => {
        //console.log(response.data);
        this.flagCount = response.data.dataRows;
        this.chartData[0].data = [
          response.data.countLate.m1,
          response.data.countLate.m2,
          response.data.countLate.m3,
          response.data.countLate.m4,
          response.data.countLate.m5,
          response.data.countLate.m6,
        ];
      });

    //FETCH STUDENT IN SCHOOL
    let formStd = new FormData();
    formStd.append("txtAction", "getAllStdBySchool");
    formStd.append("schoolCode", this.$route.params.schoolCode);
    this.axios
      .post(this.$hostUrl + "php_action/userAPI.php", formStd)
      .then((response) => {
        //console.log(response.data);
        this.stdInfo = response.data.mainData;
      });

    //FETCH MISSED STUDENT
    let formMiss = new FormData();
    formMiss.append("txtAction", "getMissByDate");
    formMiss.append("d", this.nowD);
    formMiss.append("schoolCode", this.$route.params.schoolCode);
    this.axios
      .post(this.$hostUrl + "php_action/attendanceAPI.php", formMiss)
      .then((response) => {
        console.log(response.data);
        this.missInfo = response.data.mainData;
      });
  },
  methods: {
    dateConverter(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
