<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
    v-if="userData"
    class="bg-skw-blue"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      Beaming School
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
      />
    </CSidebarBrand>
    <!--ศรีสะเกษวิทยาลัย-->
    <CRenderFunction
      flat
      :content-to-render="$options.nav"
      v-if="userData.user_address == '1033530885'"
    />
    <!--แก่นนครวิทยาลัย-->
    <CRenderFunction
      flat
      :content-to-render="$options.navKNW"
      v-else-if="userData.user_address == '1040051091'"
    />
    <!--คะแนนพฤติกรรม+เวลาเรียน-->
    <CRenderFunction
      flat
      :content-to-render="$options.navSWK"
      v-else-if="userData.user_address == '1032650764' || userData.user_address == '1032650757' || userData.user_address == '1038660537' || userData.user_address == '1033530947' || userData.user_address == '1033530895' || userData.user_address == '1032650836'"
    />
    <CRenderFunction
      flat
      :content-to-render="$options.navStd"
      v-else-if="userData.level_id"
    />
    <CSidebarBrand>
      <div
        style="width: 100%; height: 100%; font-size: 14px; padding-top: 20px"
        class="bg-yellow text-center"
      >
        {{ userData.user_address }}
        #{{ userData.stud_id }}<br />
        {{ userData.school_name }}
      </div>
    </CSidebarBrand>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from "./_nav";
import navKNW from "./_navKNW";
import navSWK from "./_navSWK";
import navStd from "./_navStd";

export default {
  name: "TheSidebar",
  data() {
    return {
      userData: this.$cookies.get("user"),

    };
  },
  nav,
  navKNW,
  navSWK,
  navStd,
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
  },
  mounted() {
    //console.log(this.userData)
  },
};
</script>
