export default [{
    _name: 'CSidebarNav',
    _children: [{
            _name: 'CSidebarNavItem',
            name: 'Dashboard',
            to: '/dashboard',
            icon: 'cil-speedometer',
            badge: {
                color: 'primary',
                text: 'NEW'
            }
        },
        {
            _name: 'CSidebarNavTitle',
            _children: ['1. เมนูครู']
        },
        {
            _name: 'CSidebarNavItem',
            name: '1.1 แผงจัดการ',
            to: '/dashboardTeacher',
            icon: 'cil-chart-pie'
        },
        {
            _name: 'CSidebarNavItem',
            name: '1.2 จัดการคะแนนรายวิชา',
            to: '/dashboardUploadScore',
            icon: 'cil-chart-pie'
        },
        {
            _name: 'CSidebarNavTitle',
            _children: ['2. ระเบียนประวัติ']
        },
        {
            _name: 'CSidebarNavItem',
            name: '2.1 แผงจัดการ',
            to: '/records/main',
            icon: 'cil-chart-pie'
        },
        {
            _name: 'CSidebarNavItem',
            name: '2.2 ตำบลที่พักอาศัยและแผนที่',
            to: '/records/subdistrict',
            icon: 'cil-chart-pie'
        },
        {
            _name: 'CSidebarNavTitle',
            _children: ['3. รายงาน']
        },
        {
            _name: 'CSidebarNavItem',
            name: '3.1 คะแนนพฤติกรรมรายชั้น',
            to: '/reports/mainReport',
            icon: 'cil-chart-pie'
        },
        {
            _name: 'CSidebarNavItem',
            name: '3.2 สรุปนักเรียนขาดเรียน',
            to: '/reports/classMissing',
            icon: 'cil-chart-pie'
        },

        {
            _name: 'CSidebarNavTitle',
            _children: ['4. เมนูผู้บริหาร']
        },
        {
            _name: 'CSidebarNavItem',
            name: '4.1 แผงจัดการ',
            to: '/dashboardAdmin',
            icon: 'cil-chart-pie'
        },



    ]
}]