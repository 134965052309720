export default [{
    _name: 'CSidebarNav',
    _children: [{
            _name: 'CSidebarNavItem',
            name: 'Dashboard',
            to: '/dashboard',
            icon: 'cil-speedometer',
            badge: {
                color: 'primary',
                text: 'NEW'
            }
        },
        {
            _name: 'CSidebarNavTitle',
            _children: ['ค่าบำรุงการศึกษา'],

        },
        {
            _name: 'CSidebarNavItem',
            name: 'ตรวจสอบ',
            to: '/dashboardTutionFee',
            icon: 'cil-speedometer',
        },
        {
            _name: 'CSidebarNavTitle',
            _children: ['Litter Bank'],

        },
        {
            _name: 'CSidebarNavItem',
            name: 'ประวัติการฝาก/ ถอน',
            to: '/dashboardLittleBank',
            icon: 'cil-speedometer',
        },


    ]
}]